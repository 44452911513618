html, body, h1, h2, li {
  /* font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif; */
  font-family: "Andale Mono", monospace;
  font-weight: 300;
  color: #FFFFFFBF;
  letter-spacing: 2px;
}

html, body {
  height: 100vh;
  width: 100vw;
  overflow: auto;
  overflow-y: hidden;
}

.vanta { 
  height: 100vh;
  width: 100vw;
  font-size: 24px;
}

.heading {
  margin: auto;
  position: static;
  width: 21%;
  min-width: 325px;
  padding: 10px;
  text-align: center;
  top: 35%;
}

h1 {
  font-size: 1.5em;
  margin: 0;
}

h2 {
  font-size: .6em;
  padding-bottom: 10px;
}

ul {
  list-style-type: none;
  display: inline-block;
  padding: 0;
}

li {
  float: left;
  margin-right: 1em;
}

li a {
  display: block;
  text-align: center;
  text-decoration: none;
  fill: white;
  opacity: 0.6;
  transition: 0.5s;
}
li a:hover {
  opacity: 1;
}

a {
  color: #FFFFFFBF;
  text-decoration: none;
}